import { match } from 'path-to-regexp';
import { getSubDomain } from '~~/common/utils';
import subSites from '@/assets/contents/sub-sites.json';
import { replaceInTpl } from '@/common/utils';
import useLeadsRecord from './user-leads-record';

const routes = {
  '/': {
    title: '${slogan}',
    keywords: '${areaCorp}海外人力外包 ${areaCorp}海外劳务派遣 ${areaCorp}海外发工资 ${areaCorp}海外劳动法 ${areaCorp}海外用工成本 ${areaCorp}海外员工招聘 ${areaCorp}海外工作签证 ${areaCorp}海外员工保险',
  },
  '/product/oversea-employ': {
    title: '${areaCorp}海外雇佣服务 - ${slogan}',
    keywords: '${areaCorp}海外人力外包 ${areaCorp}海外人力派遣 ${areaCorp}海外人力资源 ${areaCorp}海外人力招聘 ${areaCorp} ${areaCorp}海外劳务输出 ${areaCorp}海外劳务派遣 ${areaCorp}海外劳务外派 ${areaCorp}海外劳务招聘',
  },
  '/product/payroll': {
    title: '${areaCorp}全球薪酬服务 - ${slogan}',
    keywords: ' ${areaCorp}海外薪酬外包 ${areaCorp}海外薪酬支付 ${areaCorp}海外薪酬管理 ${areaCorp}海外薪酬发放 ${areaCorp}海外发工资 ${areaCorp}境外发工资 ${areaCorp}境外代发工资 ${areaCorp}跨境发工资',
  },
  '/product/poly-employ': {
    title: '${areaCorp}海外灵活用工服务 - ${slogan}',
    keywords: '${areaCorp}海外灵活用工 ${areaCorp}境外灵活用工 ${areaCorp}海外合同工 ${areaCorp}国外灵活用工',
  },
  '/product/oversea-recruitment': {
    title: '${areaCorp}海外招聘服务 - ${slogan}',
    keywords: '${areaCorp}海外人力外包 ${areaCorp}海外人力派遣 ${areaCorp}海外人力资源 ${areaCorp}海外人力招聘 ${areaCorp} ${areaCorp}海外劳务输出 ${areaCorp}海外劳务派遣 ${areaCorp}海外劳务外派 ${areaCorp}海外劳务招聘',
  },
  '/solution/global-legitimate': {
    title: '${areaCorp}全球合规 - ${slogan}',
    keywords: '海外劳动法 海外雇佣劳动法  ${areaCorp}海外用工合规 ${areaCorp}境外用工合规 ${areaCorp}海外人力合规 海外劳务合规海外劳动法 海外雇佣劳动法  ${areaCorp}海外用工合规 ${areaCorp}境外用工合规 ${areaCorp}海外人力合规 ${areaCorp}海外劳务合规',
  },
  '/solution/payroll': {
    title: '${areaCorp}海外薪酬支付 - ${slogan}',
    keywords: '${areaCorp}海外薪酬 ${areaCorp}工资支付 ${areaCorp}工资单',
  },
  '/showcase/:id': {
    title: '客户案例 - ${slogan}',
    keywords: '${areaCorp}海外人力外包 ${areaCorp}海外劳务派遣 ${areaCorp}海外发工资 海外劳动法 ${areaCorp}海外用工成本 ${areaCorp}海外员工招聘 ${areaCorp}海外工作签证 ${areaCorp}海外员工保险',
  },
  '/charge': {
    title: '收费标准 - ${slogan}',
    keywords: '${areaCorp}海外人力外包 ${areaCorp}海外劳务派遣 ${areaCorp}海外发工资 海外劳动法 ${areaCorp}海外用工成本 ${areaCorp}海外员工招聘 ${areaCorp}海外工作签证 ${areaCorp}海外员工保险',
  },
  '/info/news': {
    title: '海外动态 - ${slogan}',
    keywords: '海外动态 ${area}企业出海动态 ${areaCorp}海外人力资讯',
  },
  '/info/policy': {
    title: '政策指南 - ${slogan}',
    keywords: '${areaCorp}海外政策指南 ${areaCorp}海外法律合规 ${areaCorp}海外劳动法 ${areaCorp}海外法律法规',
  },
  '/info/guide': {
    title: '${areaCorp}出海攻略 - ${slogan}',
    keywords: '${areaCorp}出海攻略 ${areaCorp}出海干货',
  },
  '/info/news/:id': {
    title: '${title} - 海外动态 - ${slogan}',
    keywords: '海外动态 ${area}企业出海动态 ${areaCorp}海外人力资讯',
  },
  '/info/policy/:id': {
    title: '${title} - 政策指南 - ${slogan}',
    keywords: '${areaCorp}海外政策指南 ${areaCorp}海外法律合规 ${areaCorp}海外劳动法 ${areaCorp}海外法律法规',
  },
  '/info/guide/:id': {
    title: '${title} - ${areaCorp}出海攻略 - ${slogan}',
    keywords: '${areaCorp}出海攻略 ${areaCorp}出海干货',
  },
  '/resource/global': {
    title: '${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}海外用工 ${areaCorp}海外招聘 ${areaCorp}海外人力资源 ${areaCorp}海外劳务派遣 ${areaCorp}海外灵活用工 ${areaCorp}海外薪酬管理',
  },
  '/resource/global/:id': {
    title: '${title}人力外包 - ${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}${title}用工 ${areaCorp}${title}招聘 ${areaCorp}${title}人力资源 ${areaCorp}${title}劳务派遣 ${areaCorp}${title}灵活用工 ${areaCorp}${title}薪酬管理',
  },
  '/resource/global/:id/corporate-tax': {
    title: '${title}用工成本 - ${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}${title}海外税收政策 ${areaCorp}${title}企业税收政策 ${areaCorp}${title}国家税收政策',
  },
  '/resource/global/:id/labor-employment-regulations': {
    title: '${title}劳动法 - ${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}${title}海外劳动法 ${areaCorp}${title}海外就业法 ${areaCorp}${title}劳动就业法规',
  },
  '/resource/global/:id/government-departments-relevant-institutions': {
    title: '${title}工作签证 - ${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}${title}国家政府机构',
  },
  '/resource/global/:id/banks-insurance-companies': {
    title: '${title}员工保险 - ${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}${title}国家银行保险',
  },
  '/resource/global/:id/overview': {
    title: '${countryName}国家概况 - ${areaCorp}全球雇佣指南 - ${slogan}',
    keywords: '${areaCorp}${title}国家概况',
  },
  '/resource/cost-calculator': {
    title: '${title}劳务派遣 - ${slogan}',
    keywords: '${areaCorp}${title}企业雇佣员工成本 ${areaCorp}${title}海外员工工资 ${areaCorp}${title}雇佣成本计算器',
  },
  '/resource/wage-calculator': {
    title: '${title}发工资 - ${slogan}',
    keywords: '${areaCorp}${title}员工实得工资 ${areaCorp}${title}海外员工工资 ${areaCorp}${title}实得工资计算器',
  },
  '/resource/compensation-insight-tool': {
    title: '${title}薪酬洞察 - ${slogan}',
    keywords: '${areaCorp}${title}海外员工薪资 ${areaCorp}${title}全球薪酬水平 ${areaCorp}${title}员工工资水平',
  },
  '/resource/compensation-insight-tool-detail': {
    title: '${title}薪酬洞察 - ${slogan}',
    keywords: '${areaCorp}${title}海外员工薪资 ${areaCorp}${title}全球薪酬水平 ${areaCorp}${title}员工工资水平',
  },
  '/rank-list': {
    title: '出海热门榜 - ${slogan}',
    keywords: '${areaCorp}出海热门国家',
  },
  '/help-center': {
    title: '帮助中心 - ${slogan}',
    keywords: '${areaCorp}企业出海 ${areaCorp}海外用工 ${areaCorp}海外人力资源',
  },
  '/help-center/faq/:id': {
    title: '${title} - 帮助中心 - ${slogan}',
    keywords: '${areaCorp}企业出海 ${areaCorp}海外用工 ${areaCorp}海外人力资源',
  },
  '/help-center/search': {
    title: '站内搜索 - 帮助中心 - ${slogan}',
    keywords: '${areaCorp}企业出海 ${areaCorp}海外用工 ${areaCorp}海外人力资源',
  },
  '/about/company-info': {
    title: '关于公司 - ${slogan}',
    keywords: 'dghire全球雇佣 dghire海外用工 dghire薪酬管理',
  },
  '/about/competition': {
    title: '我们的优势 - ${slogan}',
    keywords: '${areaCorp}用工合规 ${areaCorp}合同合规 ${areaCorp}数据安全',
  },
  '/about/partner-program': {
    title: '合作伙伴计划 - ${slogan}',
    keywords: '${areaCorp}出海市场营销 ${areaCorp}出海产品集成 ${areaCorp}海外专业顾问',
  },
  '/about/development-capability': {
    title: '开放能力 - ${slogan}',
    keywords: '${areaCorp}开放API ${areaCorp}功能集成 ${areaCorp}数据集成',
  },
  '/about/free-consultation': {
    title: '${title} - ${slogan}',
    keywords: '${areaCorp}全球用工 ${areaCorp}全球薪酬管理',
  },
  '/landing/word/:keyword': {
    title: '${keyword} - ${slogan}',
    keywords: '${areaCorp}${keyword}',
  },
  // '*': {
  //   title: '${slogan}',
  //   keywords: '${areaCorp}全球用工 ${areaCorp}全球薪酬管理',
  // },
};

function getMatchRoute(path: string) {
  const matched = Object.entries(routes).find(([key]) => {
    const urlMatch = match(key);
    return urlMatch(path);
  });
  return matched?.[1] || {
    title: '${slogan}',
    keywords: '${areaCorp}全球用工 ${areaCorp}全球薪酬管理',
  };
}

function getHeaders(subDomain: string, path: string, params?: Record<string, string>) {
  params = {
    ...(params || {}),
    slogan: 'dghire - 全球用工与薪酬管理平台',
    area: '',
    areaCorp: '',
    areaCorpFor: '',
  };

  if (Object.prototype.hasOwnProperty.call(subSites, subDomain)) {
    params.area = subSites[subDomain as keyof typeof subSites];
    params.areaCorp = `${params.area}企业`;
    params.areaCorpFor = `为${params.areaCorp}`;
    params.slogan = `dghire - ${params.areaCorp}出海服务平台`;
  }
  const { title, keywords } = getMatchRoute(path);
  const description = 'DG.hire 在全球150个国家${areaCorpFor}提供全球雇佣和企业海外落地服务，同时有50名法律专家帮助您解决合规安全问题，帮助企业快速招聘和雇佣全球人才。';
  return {
    title: replaceInTpl(title, params),
    keywords: replaceInTpl(keywords, params),
    description: replaceInTpl(description, params),
  };
}

export default async function (params?: Record<string, string>) {
  let host;
  if (process.client) {
    host = window.location.host;
  } else {
    host = useRequestHeaders().host;
  }
  const subDomain = getSubDomain(host || '');
  const { path } = useRoute();
  const { title, keywords, description } = getHeaders(subDomain || '', path, params);
  useLeadsRecord(title);
  useHead({
    title,
    meta: [
      {
        name: 'keywords',
        content: keywords,
      },
      {
        name: 'description',
        content: description,
      },
    ],
    script: subDomain === 'www' ? [
      {
        children: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement('script');
            hm.src = "https://hm.baidu.com/hm.js?77d8767c91d4cb93a3a6cf15b9950dc6";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
          })();
        `,
      },
      {
        async: true,
        src: 'https://www.googletagmanager.com/gtag/js?id=G-BG0Y38F963',
      },
      {
        children: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-BG0Y38F963');
        `,
      },
      {
        async: true,
        src: 'https://www.googletagmanager.com/gtag/js?id=AW-11157001812',
      },
      {
        children: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11157001812');
        `,
      },
    ] : [],
  });
}
