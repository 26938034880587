export default function useLeadsRecord(title: string) {
  const recordKey = '__record_list__';
  if (!process.client || !recordKey || !title) {
    return;
  }
  let defaultRecordList: string[] = [title];
  const record = localStorage.getItem(recordKey);
  if (!record) {
    localStorage.setItem(recordKey, JSON.stringify(defaultRecordList));
    return;
  }
  defaultRecordList = JSON.parse(record);
  if (defaultRecordList && defaultRecordList[defaultRecordList.length - 1] === title) {
    return;
  }
  defaultRecordList.push(title);
  localStorage.setItem(recordKey, JSON.stringify(defaultRecordList));
}